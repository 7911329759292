

h1 {
  font-size: 40px  ;
  font-weight: 700;

  
}


h2 {
  font-size: 30px ;
  font-weight: 600; 

}

h3 {
  font-size: 24px ;
  font-weight: 600 ;

  
}


h4 {
  font-size: 20px ;
  font-weight: 500 ;
}



h5 {
  font-size: 18px ;
  font-weight: 500 ;
}


h6 {
  font-size: 16px ;
  font-weight: 400 ;
  
}

p, .form-label,th {
  font-size: 14px ;
  font-weight: 400 ;
}

 .form-check-label {
  font-size: 13px ;
  font-weight: 400 ;
  color: #5D5D5D;
}

a{
    text-decoration: none;
}

.form-control::placeholder, textarea::placeholder,.placeholder{
  color: #98A2B3;
  font-size: 14px;
}
.form-select{
  font-size: 16px !important;
}

/* App.css */

/* Style for the editor container to increase its height */

.ql-editor {
  height: 200px !important;
  
}

/* Style for the toolbar with shadow */
.ql-toolbar {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: white; /* Add a background color if needed */
}

/* Optional: Style the toolbar on focus or hover */
.ql-toolbar:focus, .ql-toolbar:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.sidebar {
  transition: transform 0.3s ease-in-out;

 display: flex;
    flex-direction: column;
 
    
}

.sidebar .nav-link{
    color:#000000;
    font-size: 16px;
   
   
}




.sidebar .nav-link:hover,.sidebar .active{
 
  color:#5A7F48 !important;
   
}

.no-border-listgroup .list-group-item {
  border: none !important;
}

.no-border-listgroup {
  border: none !important; 
}


.offscreen {
  position: absolute;
  left: -9999px;
}

/*ADMIN*/


.admin-container {
  display: flex;
  /* height: calc(100vh - 76px);  */
 
}

.admin-sidebar {
  width: 17%;
  height: 100%;
  display:flex;
  flex-direction:column;
;
  position: fixed;
  overflow-y: hidden; /* Sidebar is not scrollable */
  z-index: 1200 !important;
}

.admin-sidebar .nav-link{
  color:#FFFFFF;
  font-size: 14px;
 
 
}




.admin-sidebar .nav-link:hover, .admin-sidebar .active{

color: #5A7F48 !important;
background-color:  #FFFFFF!important;
font-weight:600 !important;
font-size:15px !important;
border-radius:5px !important;
 
}

.main-content {
  margin-left: 17%;

  width: 83%;
  height: 100%;
  overflow-y: auto; /* Only the main content is scrollable */
}

.header-admin{
  height:76px;
   /* box-shadow: 0px 4px 4px 0px #00000040;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 999; */
   background-color: white; 
   left: 17%;
}

.terms-card::before {
  content: '';
  position: absolute;
  
  height: 400px;
  width: 400px;
  background-image: url('../src/assets/new-logo.png');
  background-size: cover; /* or 'contain' based on your needs */
  background-position: center;
  opacity: 0.09;
  justify-content: center;
  margin-top: 250px;
margin-left: 450px;
margin-right: 450px;
 align-items: center;
 text-align: center;
 
  z-index: 0; /* Ensure this is behind your content */
}




.voucher-preview-text{
  font-family: 'Marshena_DEMO';
}

.form-check-input:checked {
  background-color: #5A7F48 !important;
  border-color: #5A7F48 !important;
}



.btn-primary{
  background-color: #5A7F48;
  color: #ffffff;
  border: none;
  font-family: 'Outfit';
  font-weight: 500 !important;
}

.btn-outline-primary{
color: #5A7F48;
  background-color: #ffffff;
  border: 1px solid #5A7F48 !important;
  font-weight: 500 !important;
  font-family: 'Outfit';
}
.btn-danger{
  background-color: #CE371C !important;
  color:#FFFFFF !important;
  font-weight: 500 !important;
 }

 .btn-outline-danger{
  color: #CE371C !important;
  background-color:#FFFFFF !important;
  border: 1.5px solid #CE371C !important;
  font-weight: 500 !important;
 }

 .btn-outline-danger:hover{
  background-color: #CE371C !important;
  color:#FFFFFF !important;
 }

 .btn-danger:hover{
  color: #CE371C !important;
  background-color:#FFFFFF !important;
  border: 1.5px solid #CE371C !important;
 }


.btn-outline-secondary:hover , .btn-primary:hover, .custom-pagination .page-item .page-link:hover{
  background-color:rgba(70, 155, 18, 0.2) !important;
}

.btn-secondary{
  background-color: #F6F6F6 !important;
  border:none !important;
  color: #171717 ;
  font-weight: 500 !important;
}

.btn-secondary:hover{
  background-color: #a4a4a4 !important;
}
/* .btn-primary:hover{
  color: #5A7F48 !important;
  background-color: #ffffff !important;
  border-color: #5A7F48 !important;
  border: 1px solid #5A7F48 ;
  font-family: 'Outfit';
 
} */

.csv-link{
  color:#000000;
  margin-left:20px;
}

.btn-outline-primary:hover, .custom-pagination .page-item.active .page-link:hover, .csv-link:hover {
  background-color: #5A7F48 !important;
  color: #ffffff !important;
  border: none !important;
  font-family: 'Outfit';
}
.row{
  --bs-gutter-x: 1rem !important;
}

.share-icon{
  width:48px;
  height:48px;
}
#invoice {
  width: 100%;
  box-sizing: border-box; 
}


.super{
top: 23%;
left: 84%;
 

}
.super2{
  top: 23%;
  left: 95%;
   
  
  }

.search-bar{
  border:1px solid #EAECF0;
   border-radius:8px; 
   width:380px;
}

.search-bar .form-control::placeholder{
  padding-left: 20px;
}

.border-line{
border-left:1.5px solid #E7E8F0 !important;
}

.hero{
  width: 130px;
}
.dropdown .btn{
  border: none !important;
}
.dropdown-menu{
  min-width: 8rem !important;
  margin: 5px 60px 0 0 !important;
}

.dropdown-item:hover{
  background-color:  #5A7F48 !important;
  color: #ffffff !important;
}
.category-add-dropdown .dropdown-item:hover{
  background-color:  none !important;
  color: #000000 !important;
}


/* .search-bar{
  border:1px solid #EAECF0;
   border-radius:8px; 
   width:400px;
} */



.login-
 {

.form-control::placeholder  {
    color: #B0B0B0;
    font-size: 14px;
}


.eye{
    position: absolute !important;
    
    transform: translateY(35%) !important;
    right: 20px !important;
    background-color: white !important;
    
  }
}

.product-page{
  .bg-danger{
    background-color: #F97066 !important;
  }
  
  .bg-success{
    background-color: #12B76A !important;
  }
}

.modal-header{
  border-bottom: none !important;
}

.modal-header .btn-close{
  border-radius: 50% !important;
  border: 1px solid #000 !important;
}




.full-screen-modal .modal-body {
  
  padding: 0px 25px 25px 25px !important;
  background-color: #FFF;
}

.faqButtonActive{
  background-color:#5A7F4880 !important;
  color:#333333  !important;
 font-weight: 500;
  border-left: 5px solid #5A7F48  !important;
  text-align: start;
  padding: 10px ;
}

.faqButton{
  border: none  !important;
  background-color: transparent  !important;
  font-weight: 500;
  color:#333333;
  text-align: start;
}

.faqButton:hover{
  background-color:rgba(70, 155, 18, 0.2)  !important ;
  border-left: none  !important;
  
}

.product-review{
  top: 5%;
  right: 5%;
}

.line{
  width: 100%;
height: 1px;

mix-blend-mode: normal;
opacity: 0.2;
/* Neutral/400 */

border: 1px solid #A3A3A3;
}

.line-p{
 
font-weight: 400;
font-size: 14px;
line-height: 20px;
margin-top: 15px;

color: #667085;

mix-blend-mode: normal;
opacity: 0.7;

}
 .popover, .custom-popover .popover{
  --bs-popover-max-width: 380px !important;
}
.popover .popover-arrow{
  display: none !important;

}
.price{
  color:#1D2939;
}
.modal-header{
  border-bottom: none !important;
}

.modal-header .btn-close{
  border-radius: 50% !important;
  border: 1px solid #000 !important;
}

.full-screen-modal .modal-body {
  
  padding: 0px 25px 25px 25px !important;
  background-color: #FFF;
}
.offcanvas{
  width: 500px !important;
}
.filter-dropdown-menu .dropdown-menu{
  position: relative !important;
  width: 100% !important;
}

.date-picker-container {
  width: 100% !important; 
}
.react-datepicker {
  z-index: 1050 !important;
  right:50px !important; /* Ensures it appears above other elements */
}

.step {
  display: flex;
  align-items: center;
  
  margin-bottom: 45px; /* Adjust the space between steps */
}

.step-circle {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: grey;
  border:2px solid white;
  box-shadow:0px 1px 2px 0px #0000001A;
  position: relative; /* Needed for the vertical line */
}

.step-circle.filled {
  background-color: #5A7F48;
}

.step-circle::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -20px; /* Match the margin-bottom of .step */
  /* transform: translateX(-50%); */
  height: 64px; /* Match the margin-bottom of .step */
  width: 2px;
  border-right: 3px dotted #1D2939;
  top:25px;
  

}

.step-content {
  display: flex;
  justify-content: space-between;
  margin-left: 15px; 
  
}

.step-label {
  font-size: 15px;
  font-weight: 500;
/* Make the label bold */
}

.step-time {
  font-size: 14px;
  font-weight: 400; 
  /* Smaller font size for the date and time */
}

/* Remove the line after the last step */
.stepper-container .step:last-child .step-circle::after {
  display: none;
  
}
.stepper-container .step:last-child{
  margin-bottom: 0px;
}


.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  appearance: none; /* Removes the default select styling */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
  /* Make space for the icon */
}

.select-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Makes the icon non-interactive */
}
.item-container{
  display: flex;
  overflow-x: auto;
  gap:10px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  scrollbar-width: none; /* Firefox */
}
/* CategoryBar.css */

.category-container {
  display: flex;
  overflow-x: auto;
  gap:15px;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  scrollbar-width: none; /* Firefox */
}

.category-container::-webkit-scrollbar, .item-container::-webkit-scrollbar {
  display: none; 
}

.category-item{
  flex: 0 0 auto; 
  color: #D7D7D7;
font-size: 14px;
font-weight: 500;


  white-space: nowrap; 

  cursor: pointer;
}

.order-item{
  color: #717171;
}

.category-item.active, .order-item.active {
 border-bottom: 2px solid #5A7F48;
  color: #2e7403;
  font-weight: 600;
 
}



.custom-pagination .page-item .page-link {
  color: #6C757D;
  background-color: #ffffff;
  border: 1px solid #6C757D !important;
  font-weight: 500 !important;
  font-family: 'Outfit';
  border-radius: 5px;
  
 
}

.custom-pagination .page-item.active .page-link {
  color: #5A7F48;
  background-color: #ffffff;
  border: 1px solid #5A7F48 !important;
  font-weight: 500 !important;
  font-family: 'Outfit';
  border-radius: 5px;
  
}

.custom-pagination .page-item.disabled .page-link {
  border: none;
  color: #C4CDD5;
}


.basket-image{
  width: 100%;
}
@media (max-width: 1200px) {
  
  /* .border-line{
    border-left:none !important;
    } */
    .terms-card::before {
   
      margin-left: 350px;
      margin-right: 350px;
      margin-top: 250px;
    }
    .search-bar{
      width: 300px;
    }
    .basket-image{
      width: 50%;
    }
  }

@media (max-width: 992px) {
  
  

    .basket-image{
      width: 50%;
    }
      .product-review{
        top: 5%;
        left: 48%;
      }
      .terms-card::before {
   
        margin-left: 250px;
        margin-right: 250px;
        margin-top: 220px;
      }

      .search-bar{
        width: 300px;
      }
}



@media (max-width: 830px) {
  
  


    .search-bar{
      width: 260px;
    }
}




@media (max-width: 768px) {
  .border-line{
    border-left:none !important;
    }
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px; 
  }
  
  h3 {
    font-size: 20px;
   
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 15px;
  }
  h6{
    font-size: 14px;
  }
  p, .form-label, .form-check-label, th {
    font-size: 12px;
  }
  .form-control::placeholder, ::placeholder, .placeholder{
  
    font-size: 13px !important;

}
.form-select{
  font-size: 14px !important;
}

.terms-card::before {
   
  margin-left: 160px;
  margin-right: 160px;
  margin-top: 200px;
}
.basket-image{
  width: 60%;
}

.search-bar{
  width: 220px;
}
 
}


@media (max-width: 576px) { 

  .search-bar{
    width: 100%;
  }

   .popover {
    --bs-popover-max-width: 280px !important;/* Or whatever width you prefer */
  }

  .faqButton:hover{
   
    background-color: #5A7F48 !important;
    color: #ffffff !important;
   font-weight: 500;
    border-left: none !important;
    text-align: start;
    padding: 10px ;
  }
 

 .btn-primary:hover{
    background-color: #5A7F48 !important;
    color: #ffffff !important;
  }

 .btn-outline-danger:hover{
  background-color: #CE371C !important;
  color:#FFFFFF !important;
 }

 .btn-danger:hover{
  color: #CE371C !important;
  background-color:#FFFFFF !important;
  border: 1.5px solid #CE371C !important;
 }

  .dropdown-item:hover{
    background-color:  none !important;
    color: none !important;
  }
.modal{
    z-index: 10000 !important;
  }
  .full-screen-modal .modal-dialog, .offcanvas {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .full-screen-modal .modal-content {
    height: 100%;
    border-radius: 0;
    border: none;
  }
  .full-screen-modal .modal-body {
    overflow-y: auto;
    padding: 0 !important;
    background-color: #FBFDFF;
  }

  .modal-body .content{
    padding: 0px 20px;
 
  
  }

  .modal-body .card-img{
    /* position: relative;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border-top-right-radius: none;
    border-top-left-radius: none;
  object-fit:cover;
  background-size: cover;
  background-position: center;
   box-Shadow: 0 2px 100px 0 rgba(156, 156, 156, 0.25);
   top: -50px;
  
    */
 
    height: 400px !important;
   object-fit: cover;
    
  }
  .price{
    color: #5A7F48 !important;
  }

  /* .header-content{
    padding-top: 100px !important;
  } */
  .hero{
    width: 62px;
  }
  .terms-card::before {
   
    margin-left: 0;
    margin-right: 0;
    margin-top: 200px;
  }
  .share-icon{
    width:35px;
    height:35px;
  }

 .leaf{
  width:24px;
 }
}


@media (max-width: 556px) {
  
  
    .product-review{
      top: 5%;
      left: 68%;
    }
}


